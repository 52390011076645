import React, { useEffect, useState, useRef } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { ApiService } from "../../Components/Services/apiservices";
import { useNavigate, useParams } from "react-router-dom";



function Thankyou() {
  const didMountRef = useRef(true);
  const [orderData, setOrderData] = useState({});
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const { id } = useParams();
  const [settingData, setSettingData] = useState([]);
  const [showFeedback, setShowFeedback] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (didMountRef.current) {
      localStorage.removeItem("CART_SESSION")
      localStorage.removeItem("ADDRESS_SESSION")
      localStorage.removeItem("COUPON_SESSION")
      localStorage.removeItem("GUEST_CHECKOUT")
      getOrderData();
      getSettingsData();
    }
    didMountRef.current = false;
  }, []);
  const getSettingsData = () => {
    ApiService.fetchData("settingsdata").then((res) => {
      if (res.status == "success") {
        setSettingData(res.sitesettings);
      }
    });
  };
  const getOrderData = () => {
    if (id) {
      const getOrderDetail = {
        trans_id: id,
      };
      ApiService.postData("get-order-detail", getOrderDetail).then((res) => {
        if (res.status === "success") {
          setOrderData(res.row_orders_data);
          setSpinnerLoading(false);
        } else {
          setSpinnerLoading(false);
        }
      });
    }
  };
  return (
    <>
      <BrowserView>
        <div className="thankusection">
          <div className="thankusection-left">
            <a href="/" className="logo">
              <img src="/img/logo.png" alt="logo" width="200" height="39" />
            </a>
            <div className="tsleft-inner mt-4">
              <div className="tsleft-header mb-5">
                <div className="tsleft-header-icon">
                  <img src="/img/check-circle.gif" />
                </div>
                <div className="tsleft-header-text">
                  <h6 className="fw400">YOUR ORDER ID: #{orderData.trans_order_number}</h6>
                  <h5 className="mb-0">Thank You {orderData.trans_user_name}!</h5>
                </div>
              </div>
              <div className="tsleft-box mb-3">
                <div className="tsleft-box-map"></div>
                <div className="tsleft-box-body">
                  <h4 className="fw400">Your order is confirmed</h4>
                </div>
              </div>
              <div className="tsleft-box">
                <div className="tsleft-box-header">
                  <h6 className="mb-0 fw400">Order Details</h6>
                </div>
                <div className="tsleft-box-body">
                  <div className="row">
                    <div className="col-lg-6">
                      <h6 className="tx-14 fw400">Contact Information</h6>
                      <p>{orderData.trans_user_email}</p>
                      <h6 className="tx-14 fw400">Shipping Address</h6>
                      <p>{orderData.trans_delivery_address}</p>
                    </div>
                    <div className="col-lg-6">
                      <h6 className="tx-14 fw400">Payment Method</h6>
                      <p>{orderData.trans_method}: {(orderData.trans_amt)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="thankusection-footer mt-3 mb-5">
              <a href="/support">Need Help? Contact Us</a>
              <div>
                <a href="/" className="btn btn-primary-outline btn-medium">Continue Shopping</a>
              </div>
            </div>
          </div>
          <div className="thankusection-right">
            {orderData.items ?
              orderData.items.map((value, index) => {
                return (
                  <div className="cartProduct" key={index}>
                    <div className="cartProductInner">
                      <a href="#" className="cartProduct-media-shap">
                        <div className="cartProduct-media">
                          <img src={value.td_item_image} />
                        </div>
                      </a>
                      <div className="cartProductDetails">
                        <h4 className="title mb-0">{value.td_item_title}</h4>
                        
                        {value.td_selected_other_variation && value.td_selected_other_variation.length > 0 && value.td_selected_other_variation.map((valueVar, index) => {
                          return (
                            <small key={index}>
                              {valueVar.attr} - {valueVar.terms}
                              <br />
                            </small>
                          );
                        })}
                        <small> Qty : {value.td_item_qty}</small>
                        <div className="cartProductFooter">
                          <div className="cartProductPrice">
                            <ins className="new-price"> {(value.td_item_sellling_price)}</ins>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
              : null}
            <div className="cartPanel pt-40">
              <h5 className="fw400">Order Summary</h5>
              <div className="cartPrice">
                <ul>
                  <li>
                    Item Total<span className="ml-auto price">₹{(orderData.item_sub_total)}</span>
                  </li>
                  <li>
                    Discount<span className="ml-auto tx-green price">₹-{(orderData.trans_discount_amount)}
                    </span>
                  </li>
                  <li>
                    Coupon Discount
                    <span className="ml-auto tx-green price">₹-{(orderData.trans_coupon_dis_amt?orderData.trans_coupon_dis_amt:"0.00")}
                    </span>
                  </li>
                  <li>
                    Shipping<span className="ml-auto price">₹{(orderData.trans_delivery_amount)}
                    </span>
                  </li>
                  
                </ul>
              </div>
              <hr></hr>
              <div className="cartPrice">
                <ul>
                  <li className="fw500">
                    Total Amount
                    <span className="price">₹{(orderData.trans_amt)}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div className="thankusection">
          <div className="thankusection-left">
            <a href="/" className="logo">
              <img src="/img/logo.png" alt="logo" width="200" height="39" />
            </a>
            <div className="tsleft-inner mt-4">
              <div className="tsleft-header mb-5">
                <div className="tsleft-header-icon">
                  <img src="/img/check-circle.gif" />
                </div>
                <div className="tsleft-header-text">
                  <h6 className="fw400">YOUR ORDER ID: #{orderData.trans_order_number}</h6>
                  <h5 className="mb-0">Thank You {orderData.trans_user_name}!</h5>
                </div>
              </div>
              <div className="tsleft-box mb-3">
                <div className="tsleft-box-map"></div>
                <div className="tsleft-box-body">
                  <h4 className="fw400">Your order is confirmed</h4>
                </div>
              </div>
              <div className="tsleft-box">
                <div className="tsleft-box-header">
                  <h6 className="mb-0 fw400">Order Details</h6>
                </div>
                <div className="tsleft-box-body">
                  <div className="row">
                    <div className="col-lg-6">
                      <h6 className="tx-14 fw400">Contact Information</h6>
                      <p>{orderData.trans_user_email}</p>
                      <h6 className="tx-14 fw400">Shipping Address</h6>
                      <p>{orderData.trans_delivery_address}</p>
                    </div>
                    <div className="col-lg-6">
                      <h6 className="tx-14 fw400">Payment Method</h6>
                      <p>{orderData.trans_method}: {(orderData.trans_amt)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="thankusection-footer mt-3 mb-5">
              <a href="/support">Need Help? Contact Us</a>
              <div>
                <a href="/" className="btn btn-primary-outline btn-medium">Continue Shopping</a>
              </div>
            </div>
          </div>
          <div className="thankusection-right">
            {orderData.items ?
              orderData.items.map((value, index) => {
                return (
                  <div className="cartProduct" key={index}>
                    <div className="cartProductInner">
                      <a href="#" className="cartProduct-media-shap">
                        <div className="cartProduct-media">
                          <img src={value.td_item_image} />
                        </div>
                      </a>
                      <div className="cartProductDetails">
                        <h4 className="title mb-0">{value.td_item_title}</h4>
                        
                        {value.td_selected_other_variation && value.td_selected_other_variation.length > 0 && value.td_selected_other_variation.map((valueVar, index) => {
                          return (
                            <small key={index}>
                              {valueVar.attr} - {valueVar.terms}
                              <br />
                            </small>
                          );
                        })}
                        <small> Qty : {value.td_item_qty}</small>
                        <div className="cartProductFooter">
                          <div className="cartProductPrice">
                            <ins className="new-price"> {(value.td_item_sellling_price)}</ins>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
              : null}
            <div className="cartPanel pt-20">
              <h5 className="fw400">Order Summary</h5>
              <div className="cartPrice">
                <ul>
                  <li>
                    Item Total<span className="ml-auto price">{(orderData.item_sub_total)}</span>
                  </li>
                  <li>
                    Discount<span className="ml-auto tx-green price">-{(orderData.trans_discount_amount)}
                    </span>
                  </li>
                  <li>
                    Coupon Discount
                    <span className="ml-auto tx-green price">-{(orderData.trans_coupon_dis_amt)}
                    </span>
                  </li>
                  <li>
                    Shipping<span className="ml-auto price">{(orderData.trans_delivery_amount)}
                    </span>
                  </li>
                  
                </ul>
              </div>
              <hr></hr>
              <div className="cartPrice">
                <ul>
                  <li className="fw500">Total Amount<span className="price">{(orderData.trans_amt)}</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </MobileView>
    </>
  );
}
export default Thankyou;
